import Vue from 'vue'
import VueRouter from 'vue-router'
import ClearPage from '@/views/ClearPage.vue'
import ClearView from '@/views/ClearView.vue'
import Home from '@/views/home/Home.vue';
import UserList from '@/components/user/list/UserList.vue'
import UserRights from '@/components/user/rights/UserRights.vue'
import PermissionRequestList from '@/components/permissionrequests/list/PermissionRequestList.vue'
import AdGroupList from '@/components/adgroup/list/AdGroupList.vue'
import ConfigEmailsList from 'ebg-vue-common/src/components/configs/emails/list/ConfigEmailsList.vue';
import UserTokenLogin from 'ebg-vue-common/src/components/user/tokenlogin/UserTokenLogin.vue';
import ScheduledTasksList from 'ebg-vue-common/src/components/scheduledtasks/list/ScheduledTasksList.vue';
import TranslationsList from 'ebg-vue-common/src/components/translations/list/TranslationsList.vue'
import CompanyList from '@/components/company/list/CompanyList.vue'
import DepartmentList from '@/components/company/departments/list/DepartmentList.vue'
import DivisionList from '@/components/company/divisions/list/DivisionList.vue'
import JobList from '@/components/company/jobs/list/JobList.vue'
import LogGeneral from 'ebg-vue-common/src/components/log/general/LogGeneral.vue'
import CustomTreeList from '@/components/common/customtree/list/CustomTreeList.vue'
import CustomTreeView from '@/components/common/customtree/view/CustomTreeView.vue'
import CustomFormList from '@/components/common/customform/form/list/CustomFormList.vue'
import CustomFormView from '@/components/common/customform/form/view/CustomFormView.vue'
import CustomFormRecordEdit from '@/components/common/customform/record/edit/CustomFormRecordEdit.vue'
import CustomFormRecordAction from '@/components/common/customform/record/action/CustomFormRecordAction.vue'
import FormRequestList from '@/components/customform/requests/list/FormRequestList.vue'
import CustomFormTypeList from '@/components/common/customform/type/list/CustomFormTypeList.vue';
import BenefitsList from '@/components/benefits/list/BenefitsList.vue'
import ContentBlocksList from '@/components/contentblocks/list/ContentBlocksList.vue'
import MyData from '@/components/user/mydata/MyData.vue'
import UserResetPassword from '@/components/user/resetpassword/UserResetPassword.vue'
import UserEdit from '@/components/user/edit/UserEdit.vue';
import ConstantList from 'ebg-vue-common/src/components/configs/constant/list/ConstantList.vue'
import QualificationCategoryList from '@/components/constants/qualifications/categorylist/QualificationCategoryList.vue'
import QualificationList from '@/components/constants/qualifications/list/QualificationList.vue'
import QualificationTypeList from '@/components/constants/qualifications/typelist/QualificationTypeList.vue'
import Login from '@/views/login/Login.vue';
import Landing from '@/components/pages/Landing.vue';
import CompanyTree from '@/components/common/companytree/CompanyTree.vue';
import UserBenefits from '@/components/benefits/user/UserBenefits.vue';
import AxaptaSalaryList from '@/components/axapta/user/salary/list/AxaptaSalaryList.vue';
import SnipeAssetUserAssets from '@/components/snipeasset/user/assets/SnipeAssetUserAssets.vue';
import CustomTreePickerView from '@/components/common/customtree/pickerview/CustomTreePickerView.vue';
import UserHoliday from '@/components/user/holiday/list/UserHoliday.vue';
import IssueReportList from 'ebg-vue-common/src/components/common/issuereport/list/IssueReportList.vue'
import NewsList from '@/components/news/list/NewsList.vue';
import NewsEdit from '@/components/news/edit/NewsEdit.vue';
import ApplicationFormsIndex from '@/components/applications/forms-index/ApplicationFormsIndex.vue';
import InstructionsList from '@/components/moodle/instructions/InstructionsList.vue';
import AdvancePaymentReport from '@/components/reports/advancepayment/AdvancePaymentReport.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'menu.user.login',
    component: Login,
    meta: { public: true, noSession: true }
  },
  {
    path: '/reset-password/:apiId?',
    name: 'menu.user.reset-password',
    component: UserResetPassword,
    meta: { public: true, noSession: true }
  },
  {
    path: '/tokenlogin',
    name: 'menu.user.tokenlogin',
    component: UserTokenLogin,
    meta: { public: true, noSession: false }
  },

  {
    path: '/home',
    name: 'menu.home.title',
    component: Home,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null, shiftpicker: true, homeroute: true }
  },
  {
    path: '/my',
    title: 'menu.user.my-data',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: '',
        redirect: { name: 'menu.home.landing' }
      },
      {
        path: 'data',
        name: 'menu.user.my-data',
        component: MyData,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' }
      },
      {
        path: 'holidays',
        name: 'menu.home.holidays',
        component: UserHoliday,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' }
      },
      {
        path: 'salary-cards',
        name: 'menu.home.salary-cards',
        component: AxaptaSalaryList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' }
      },
      {
        path: 'benefits',
        name: 'menu.home.benefits',
        component: UserBenefits,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' }
      },
      {
        path: 'inventory',
        name: 'menu.home.inventory',
        component: SnipeAssetUserAssets,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' },
        props: { useLoggedInUser: true }
      },
      {
        path: 'advance-applications',
        name: 'menu.home.advance-applications',
        component: FormRequestList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/my' },
        props: { useLoggedInUser: true, formType: 'advance', onlyMyRequest: true }
      },
      {
        path: 'instructions',
        name: 'menu.home.my-instructions',
        component: InstructionsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['beta-tester-access'], parentPath: '/my' }
      }
    ]
  },
  {
    path: '/custom-forms',
    name: 'menu.user.applications',
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    component: ClearPage,
    children: [
      {
        path: 'application-forms',
        name: 'menu.user.application-forms',
        component: ApplicationFormsIndex,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/application-forms' }
      },
      {
        path: 'form',
        name: 'menu.forms.edit-forms',
        component: ClearView,
        redirect: { name: 'menu.forms.edit-forms' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-custom-forms'], parentPath: '/custom-forms' },
        children: [
          {
            path: '',
            name: 'menu.forms.edit-forms',
            component: CustomFormList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-custom-forms'], parentPath: '/form' }
          },
          {
            path: 'edit/:formid',
            name: 'menu.forms.form-edit',
            component: CustomFormView,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-custom-forms'], parentPath: '/form' }
          },
          {
            path: 'edit/:formid/:recordid',
            name: 'menu.forms.record-edit',
            component: CustomFormRecordEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: null, parentPath: '/form' }
          }
        ]
      },
      {
        path: 'holiday-applications',
        name: 'menu.user.holiday-applications',
        component: FormRequestList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/custom-forms' },
        props: { formType: 'holiday' }
      },
      {
        path: 'advance-applications',
        name: 'menu.user.advance-applications',
        component: FormRequestList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/custom-forms' },
        props: { formType: 'advance' }
      },
      {
        path: 'list-form-types',
        name: 'menu.forms.list-form-types',
        component: CustomFormTypeList,
        meta: { public: true, noSession: false, isMenuItem: true, permissions: ['edit-custom-forms'], parentPath: '/custom-forms' }
      },
      {
        path: 'advance-payment-report',
        name: 'menu.forms.advance-payment-report',
        component: AdvancePaymentReport,
        meta: { public: true, noSession: false, isMenuItem: true, permissions: ['view-advance-payment-requests-report'], parentPath: '/custom-forms' }
      }
    ]
  },
  {
    path: '/about-company',
    title: 'menu.about-company.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: '',
        redirect: { name: 'menu.home.landing' }
      },
      {
        path: 'landing',
        name: 'menu.about-company.title',
        component: Landing,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/about-company' }
      },
      {
        path: 'tree-organization',
        name: 'menu.home.tree-organization',
        component: CustomTreePickerView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/about-company' }
      },
      {
        path: 'rights',
        name: 'menu.home.rights',
        component: UserRights,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/about-company' }
      },
      {
        path: 'tree-logical',
        name: 'menu.home.tree-logical',
        component: CompanyTree,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/about-company' }
      },
      {
        path: 'news',
        name: 'menu.home.news',
        component: NewsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/about-company', allowEdit: false }
      }
    ]
  },
  {
    path: '/user',
    title: 'menu.user.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'list',
        name: 'menu.user.employee-contacts',
        component: ClearView,
        redirect: { name: 'menu.user.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: null, parentPath: '/user' },
        children: [
          {
            path: '',
            name: 'menu.user.list',
            component: UserList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: null, parentPath: 'list' }
          },
          {
            path: ':api_id',
            name: 'menu.user.view',
            component: UserEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-all-users'], parentPath: '' }
          }
        ]
      }
      /*
      {
        path: 'birthdays',
        name: 'menu.user.employees-birthdays',
        component: UserBirthdays,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-user-birthdays'], parentPath: '/user' }
      }
      */
    ]
  },
  {
    path: '/requests',
    title: 'menu.requests.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-requests'] },
    children: [
      {
        path: '',
        redirect: { name: 'menu.requests.folder-requests' }
      },
      {
        path: 'folder-requests',
        name: 'menu.requests.folder-requests',
        component: PermissionRequestList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-requests'], parentPath: '/requests' }
      },
      {
        path: 'system-requests',
        name: 'menu.requests.system-requests',
        component: PermissionRequestList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-requests'], parentPath: '/requests' }
      }
    ]
  },
  {
    path: '/companies',
    title: 'menu.companies.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings', 'edit-all-custom-trees', 'edit-company-custom-trees'] },
    children: [
      {
        path: '',
        redirect: { name: 'menu.companies.list' }
      },
      {
        path: 'list',
        name: 'menu.companies.list',
        component: CompanyList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/companies' }
      },
      {
        path: 'deparments',
        name: 'menu.companies.departments',
        component: DepartmentList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/companies' }
      },
      {
        path: 'divisions',
        name: 'menu.companies.divisions',
        component: DivisionList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/companies' }
      },
      {
        path: 'jobs',
        name: 'menu.companies.jobs',
        component: JobList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/companies' }
      },
      {
        path: 'custom-trees',
        name: 'menu.custom-tree.list',
        component: ClearView,
        redirect: { name: 'menu.custom-tree.lists' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-all-custom-trees', 'edit-company-custom-trees'], parentPath: '/companies' },
        children: [
          {
            path: '',
            name: 'menu.custom-tree.lists',
            component: CustomTreeList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-all-custom-trees', 'edit-company-custom-trees'], parentPath: '/custom-trees' }
          },
          {
            path: ':treeid',
            name: 'menu.custom-tree.view',
            component: CustomTreeView,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-all-custom-trees', 'edit-company-custom-trees'], parentPath: '/custom-trees' }
          }
        ]
      }
    ]
  },
  {
    path: '/constants',
    name: 'menu.constants.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: '',
        redirect: { name: 'menu.constants.contact-person-types' }
      },
      {
        path: 'contact-person-types',
        name: 'menu.constants.contact-person-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-contact-person-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'contact-person-types',
          configpath: 'config/hr/contact-person-types'
        }
      },
      {
        path: 'job-security-types',
        name: 'menu.constants.job-security-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-job-security-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'job-security-types',
          configpath: 'config/hr/job-security-types'
        }
      },
      {
        path: 'security-category-types',
        name: 'menu.constants.dd-category-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-job-security-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'security-category-types',
          configpath: 'config/hr/security-category-types'
        }
      },
      {
        path: 'education-levels',
        name: 'menu.constants.education-levels',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-education-levels'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'education-levels',
          configpath: 'config/hr/education-levels'
        }
      },
      {
        path: 'education-places',
        name: 'menu.constants.education-places',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-education-places'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'education-places',
          configpath: 'config/hr/education-places'
        }
      },
      {
        path: 'education-specialities',
        name: 'menu.constants.education-specialities',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-education-specialities'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'education-specialities',
          configpath: 'config/hr/education-specialities'
        }
      },
      {
        path: 'education-types',
        name: 'menu.constants.education-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-education-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'education-types',
          configpath: 'config/hr/education-types'
        }
      },
      {
        path: 'qualification-types',
        name: 'menu.constants.qualification-types',
        component: QualificationTypeList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-qualification-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'qualification-types',
          configpath: 'config/hr/qualification-types'
        }
      },
      {
        path: 'qualifications',
        name: 'menu.constants.qualifications',
        component: QualificationList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-qualifications'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'qualifications',
          configpath: 'config/hr/qualifications'
        }
      },
      {
        path: 'qualification-categories',
        name: 'menu.constants.qualification-categories',
        component: QualificationCategoryList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-qualification-categories'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'qualification-categories',
          configpath: 'config/hr/qualification-categories'
        }
      },
      {
        path: 'contract-types',
        name: 'menu.constants.contract-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-contract-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'contract-types',
          configpath: 'config/hr/contract-types'
        }
      },
      {
        path: 'working-time-types',
        name: 'menu.constants.working-time-types',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-working-time-types'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'working-time-types',
          configpath: 'config/hr/working-time-types'
        }
      },
      {
        path: 'texts',
        name: 'menu.constants.texts',
        component: ConstantList,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-texts'],
          parentPath: '/constants',
          isConfigPage: false,
          configPermabase: 'texts',
          configpath: 'config/hr/texts'
        }
      }
    ]
  },
  {
    path: '/config',
    title: 'menu.config.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: '',
        redirect: { name: 'menu.admin.benefits' }
      },
      {
        path: 'benefits',
        name: 'menu.admin.benefits',
        component: BenefitsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/config', isConfigPage: false }
      },
      {
        path: 'content-blocks',
        name: 'menu.admin.content-blocks',
        component: ContentBlocksList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-company-settings'], parentPath: '/config', isConfigPage: false }
      },
      {
        path: 'ad-groups',
        name: 'menu.config.ad-groups.title',
        component: AdGroupList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/config', isConfigPage: true }
      },
      {
        path: 'emails',
        name: 'menu.config.emails.title',
        component: ConfigEmailsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-settings'], parentPath: '/config', isConfigPage: true }
      },
      {
        path: 'general-log',
        name: 'menu.admin.general-log',
        component: LogGeneral,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/config', isConfigPage: false }
      },
      {
        path: 'scheduled-tasks',
        name: 'menu.admin.scheduled-tasks',
        component: ScheduledTasksList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-cron'], parentPath: '/config', isConfigPage: true }
      },
      {
        path: 'translations',
        name: 'menu.admin.translations',
        component: TranslationsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/config', isConfigPage: false }
      },
      {
        path: 'issue-reports',
        name: 'menu.issue-reports.title',
        component: IssueReportList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-issue-reports'], parentPath: '/config', isConfigPage: false }
      },
      {
        path: 'news',
        title: 'menu.admin.news',
        name: 'menu.admin.news',
        component: ClearView,
        redirect: { name: 'menu.admin.news-list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-news'], parentPath: '/config' },
        children: [
          {
            path: '',
            name: 'menu.admin.news-list',
            component: NewsList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-news'], parentPath: '/news', allowListEdit: true }
          },
          {
            path: 'edit/:id',
            name: 'menu.admin.news-edit',
            component: NewsEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['edit-news'], parentPath: '/news' }
          }
        ]
      }
    ]
  },
  {
    path: '/custom-forms/action/:token',
    title: 'menu.forms.title',
    component: CustomFormRecordAction,
    meta: { public: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({
      path: '/login'
    });
  } else {
    if (router.app.$session.exists() && to.name === 'menu.user.reset-password') {
      router.app.$session.destroy();
    }
    if (!router.app.$session.exists() && !to.meta.public) {
      next({
        path: '/login'
      });
    } else if (router.app.$session.exists() && to.meta.noSession) {
      next({
        path: '/home'
      });
    } else {
      if (!to.meta.public) {
        const permissions = router.app.$session.get('permissions');
        if (to.meta.permissions === null || to.meta.permissions.filter(Set.prototype.has, new Set(permissions)).length > 0) {
          next();
        } else {
          next({ path: '/home' });
        }
      } else {
        next();
      }
    }
  }
})

export default router
